@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/fonts/Arial_Unicode.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* SF Compact Display */
@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/fonts/SF-Compact-Display-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/fonts/SF-Compact-Display-Ultralight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/fonts/SF-Compact-Display-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/fonts/SF-Compact-Display-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/fonts/SF-Compact-Display-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/fonts/SF-Compact-Display-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/fonts/SF-Compact-Display-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/fonts/SF-Compact-Display-Heavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'SFPRO';
    src: url('../../public/assets/fonts/SF-Compact-Display-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}






:root {
    --mantine-color-scheme: 'light' !important;
    --main-color: white;
    --base-gray: #000000;
    --off-gray: #f1f1f1;
    --item-gray: #9D9D9D;
    --text: #000000;
    --text-gray: #6B6A69;
    --trans: #ffffff00;
    --px11: 0.6875rem;
    --px12: 0.75rem;
    --px13: 0.813rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px17: 1.0625rem;
    --px18: 1.125rem;
    --px19: 1.1875rem;
    --px20: 1.25rem;
    --px21: 1.3125rem;
    --px22: 1.375rem;
    --px23: 1.4375rem;
    --px24: 1.5rem;
    --px25: 1.5625rem;
    --px26: 1.625rem;
    --px27: 1.6875rem;
    --px28: 1.75rem;
    --px29: 1.8125rem;
    --px30: 1.875rem;
    --titleFont: 2.2rem;

    --hoverBorder: rgba(0, 0, 0, 0.068);
    --frostedBorder: solid 1px rgba(255, 255, 255, 0.113);
    --heroFont: 2.1rem;
    --frostedBackground: rgb(22 22 22);
    ;
    --lightSurface: #1d1d1da1;
    --glassBackground: rgb(255 255 255 / 2%);
    --glassSurface: rgb(255 255 255 / 4%);
    --glassText: rgba(255, 255, 255, 0.633);
    --grayBackground: #f1f3f5;
    --gradientedFrostedBorder: solid 1.5px;
    --frostedText: rgba(255, 255, 255, 0.7);
    --cornerRounding: 30px;
    --iconGray: rgba(106, 105, 106, 1);
    --headerHeight: 80px;
}

button {
    cursor: pointer;
}

button:focus {
    outline: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'SFPRO', sans-serif;
    color: rgb(0, 0, 0);

}

body {
    background-color: white !important;
}


.grouped-logo>img {
    width: 230px;
}

.grouped-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.grouped-logo>h1 {
    font-size: 24px;
    font-weight: 600;
    margin-left: 10px;
}

.grouped-divider {
    width: 1px;
    height: 20px;
    background-color: var(--item-gray);
    opacity: 0.7;
}

.demo-card {
    box-shadow: 0px 44px 169px 0px rgba(0, 0, 0, 0.11);
    width: 680px;
    border-radius: 23px;
    padding: 35px;
}

.demo-card-container {
    display: flex;
    justify-content: center;

}

.demo-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 60px;
}

.demo-card-header>h2 {
    font-size: 22px;
    font-weight: 700;
}

.demo-card-content {
    display: grid;
    grid-template-columns: 230px 1fr;
}

.demo-rail {
    display: flex;
    flex-direction: column;
}

.demo-rail-item {
    border: none;
    display: flex;
    padding: 13px;
    padding-left: 20px;
    background-color: transparent;
    font-weight: 600;

}

.demo-rail-item.active {
    background-color: var(--off-gray);
    border-radius: 10px;
}

.demo-rail>button:last-of-type {
    color: red;
}


.demo-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.smaller-grouped-logo {
    gap: 7px;
}

.smaller-grouped-logo>.appclip-logo {
    width: 110px;
}

.smaller-grouped-logo>img:last-of-type {
    width: 170px;
}






















.activity-launcher {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.activity-launcher-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100%;
    padding-top: 40px;
}

.qr-code-container {
    width: 450px;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 44px 169px 0px rgba(0, 0, 0, 0.11);
    border-radius: 23px;
    gap: 20px;
}

.qr-code-container>img {
    width: 280px;
    padding-top: 30px;
}

.qr-code-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
}

.qr-code-text>span {
    font-size: 17px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: -6px;
}

.qr-code-text>h3 {
    font-size: 28px;
    font-weight: 600;

}

.activity-info-container {
    display: grid;
    grid-template-rows: 1fr 95px;
    height: 600px;
    width: 550px;
    justify-content: center;
}

.activity-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.activity-info-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.activity-info>img {
    width: 85px;
    background-color: white;
    box-shadow: 0px 24px 84px 1px rgba(0, 0, 0, 0.20);
    border-radius: 20px;
}

.activity-info-text>h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: -7px;
}

.activity-info-text>a {
    text-decoration: none;
}

.activity-info-text>a:hover {
    text-decoration: underline;
}

.activity-info-container>span {
    text-align: center;
    font-size: 14px;
    opacity: 0.5;
}